<template>
  <section>
    <font-awesome-icon icon="exclamation" size="lg" />
    <h2>{{ $t("not-found.title") }}</h2>
    <p>{{ $t("not-found.message") }}</p>
    <router-link
      :to="{ name: 'StudentHome' }"
      class="btn btn-primary"
      role="button"
      >{{ $t("shared.go-home-page") }}</router-link
    >
  </section>
</template>

<style scoped>
section {
  max-width: 600px;
  margin: 100px auto 0 auto;
  text-align: center;
}
</style>
